<template>
  <div>
    <b-row>
      <b-col v-for="(item, index) in rooms" :key="'room-'+index" cols="12" sm="6" xl="3" class="mb-3">
        <dashboard-collapse-link-list
            :room-id="item.roomId"
            :title="genRoomTitle(index)"
            :info="item.info"
            :room-name="item.roomName"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DashboardCollapseLinkList from '@/components/dashboards/dashboard-collapse-link-list'
import { allRooms } from '@/modules/dashboards/http/allRooms'

function prefixZero (num) {
  return (num + '').padStart(2, '0')// num < 10 ? '0' + num : num
}

export default {
  name: 'TodasSalas',
  components: { DashboardCollapseLinkList },
  data: () => ({
    rooms: []
  }),
  computed: {
    filterDates () {
      return {
        startDate: this.$store.state.dashboards.filterStartDate,
        endDate: this.$store.state.dashboards.filterEndDates
      }
    }
  },
  methods: {
    genRoomTitle (index) {
      return `Sala ${prefixZero(index + 1)}`
    },
    async updateRoomsList () {
      const data = await allRooms()
      this.rooms = data.map(item => (
        {
          roomId: item.id,
          info: 'popover',
          roomName: item.name
        })
      )
    }
  },
  watch: {
    filterDates: {
      handler () {
        this.fetchData()
      },
      deep: true
    }
  },
  created () {
    this.updateRoomsList()
  }
}
</script>

<style scoped>

</style>
