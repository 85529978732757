<template>
  <dashboard-card
      :title="title"
      :info="info"
  >
    <div class="cll-container">
      <div class="cll-header">
        <div class="cll-img">
          <img src="@/assets/images/icons/meeting.png" alt="meeting">
        </div>
        <div class="cll-title">{{ roomName }}</div>
        <div class="cll-toggle">
          <b-button variant="link" @click="toggleCollapse">
            <i class="bx bx-chevron-down" :class="{'bx-rotate-180': visible}"></i>
          </b-button>
        </div>
      </div>
      <div class="cll-content">
        <b-collapse :id="collapseId" v-model="visible">
          <div class="cll-link-list">
            <router-link :to="{name: 'DashboardRoomDetail', params: {id: roomId}}" class="cll-link-item">
              Dados gerais
            </router-link>
            <router-link :to="{name: 'DashboardQuizDetail', params: {id: roomId}}" class="cll-link-item">
              Quiz
            </router-link>
            <router-link :to="{name: 'DashboardSurveyDetail', params: {id: roomId}}" class="cll-link-item">
              Enquete
            </router-link>
            <router-link :to="{name: 'DashboardWordCloudDetail', params: {id: roomId}}" class="cll-link-item">
              Nuvem de palavras
            </router-link>
          </div>
        </b-collapse>
      </div>
    </div>
  </dashboard-card>
</template>

<script>
import DashboardCard from '@/components/dashboards/dashboard-card'

export default {
  name: 'dashboard-collapse-link-list',
  components: { DashboardCard },
  props: {
    roomId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    roomName: {
      type: String,
      required: true
    },
    info: {
      type: String,
      required: true
    }
  },
  data: () => ({
    visible: false,
    collapseId: 'collapse-' + Math.random().toString(36).substr(-5)
  }),
  methods: {
    toggleCollapse () {
      this.visible = !this.visible
    }
  }
}
</script>

<style scoped lang="scss">
.cll-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 250px;

  .cll-img {
    width: 160px;
    height: 160px;
    display: grid;
    place-items: center;

    img {
      width: 55px;
      height: 55px;
      object-fit: contain;
    }
  }

  .cll-title {
    font-size: 14px;
    font-weight: 500;
    color: #5F6368;
  }

  .cll-toggle {
    margin-top: 12px;
    i {
      font-size: 27px;
    }
  }
}

.cll-content {
  .cll-link-item {
    background: #F4F6FC;
    border-radius: 7px;
    padding: 8px 12px;
    font-weight: 300;
    font-size: 12px;
    cursor: pointer;
    display: block;
    color: #5F6368;
    margin-bottom: 9px;
  }
}
</style>
